import React, {useState} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import LoginPage from "./login_module";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";

const Home = (props) => {
    const {firstName, logoutTimer, finishedLoggingIn} = useUser();


    return (
        <>
            {
                finishedLoggingIn ?
                <>
                    {/* Header */}
                    <div className={GenStyles.welcome_line}>Welcome, {firstName}!</div>
                    <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                        <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                        <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Applied Language Institute</b></h1>
                        <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                    </div>
                    {firstName ? '' : <LoginModule />}
                </> :
                <div>loading</div>
            }
        </>
    );
}

export default Home;
