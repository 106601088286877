import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import GenStyles from "./styles/GenStyles.module.css"
import {useUser} from "./UserContext";
import LoginPage from "./login_module";

const Layout = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const {userId, firstName} = useUser();

    const close = () => {
        setShowSidebar(false);

        document.getElementById("mySidebar").style.display = "none";
        document.getElementById("myOverlay").style.display = "none";
    }
    const open = () => {
        if(showSidebar) {
            close();
            return;
        }

        setShowSidebar(true);
        document.getElementById("mySidebar").style.display = "block";
        document.getElementById("myOverlay").style.display = "block";
    }


    return <>
        {/* SIDEBAR MENU */}
        <nav
            className={`${GenStyles.sidebar} ${GenStyles.sidebar_collapse} ${GenStyles.umkc_blue}`}
            id="mySidebar"
        >
            <br/>
            <a
                onClick={close}
                className={`${GenStyles.button} ${GenStyles.hide_large} ${GenStyles.display_topleft}`}
                style={{width: "100%", fontSize: "22px"}}
            >
                Close Menu
            </a>
            <div className={GenStyles.container}>
                <h3 className={`${GenStyles.padding_64} ${GenStyles.text_umkc_yellow}`}><b>Applied<br/>Language<br/>Institute</b>
                </h3>
            </div>
            <div className={GenStyles.welcome_message}>
                {firstName ? "Welcome, " + firstName : ""}
            </div>
            <div className={GenStyles.bar_block}>
                <a
                    href="/"
                    onClick={close}
                    className={`${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}`}
                >
                    Home
                </a>
                <a
                    href="examinees"
                    onClick={close}
                    className={userId ?
                        `${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}` :
                        GenStyles.hidden}
                >
                    Examinees
                </a>
                <a
                    href="basic-chat"
                    onClick={close}
                    className={userId ?
                        `${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}` :
                        GenStyles.hidden}
                >
                    Basic Chat
                </a>
                <a
                    href="tts"
                    onClick={close}
                    className={userId ?
                        `${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}` :
                        GenStyles.hidden}
                >
                    Text to Speech
                </a>
                <a
                    href="stt"
                    onClick={close}
                    className={userId ?
                        `${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}` :
                        GenStyles.hidden}
                >
                    Speech to Text
                </a>
                <a
                    href="logout"
                    className={userId ? `${GenStyles.bar_item} ${GenStyles.button} ${GenStyles.hover_white}` : GenStyles.hidden}
                >
                    Logout
                </a>
            </div>
        </nav>

        {/* Top menu on small screens */}
        <header
            className={`${GenStyles.header} ${GenStyles.hide_large} ${GenStyles.umkc_blue}`}
        >
            <a
                className={`${GenStyles.button} ${GenStyles.sidebar_open_button} ${GenStyles.umkc_blue}`}
                onClick={open}
            >
                ☰
            </a>
            <span style={{fontSize: "12px"}}>Applied Language Institute</span>

        </header>

        {/* Overlay effect when opening sidebar on small screens */}
        <div
            className={`${GenStyles.overlay} ${GenStyles.hide_large}`}
            onClick={close}
            style={{cursor: "pointer"}}
            title="close side menu"
            id="myOverlay"
        ></div>
        <div className={GenStyles.banner_image}>
        </div>
        <div className={GenStyles.main}>
            <Outlet />
        </div>
    </>
}
export default Layout;