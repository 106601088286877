import React, {createContext, useState, useContext, useEffect} from "react";
import { talk } from "./ALI_Ajax";
import GenStyles from "./styles/GenStyles.module.css";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const logoutTimerDefault = 600;
    const [email, setEmail] = useState(null);
    const [userId, setUserId] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [logoutTimer, setLogoutTimer] = useState(logoutTimerDefault);
    const [finishedLoggingIn, setFinishedLoggingIn] = useState(false);

    //USE EFFECTS
    // Timer Functionality
    useEffect(() => {
        if (!userId) return; // Don't start timer if not logged in

        const interval = setInterval(() => {
            setLogoutTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    logout(); // Log out when timer reaches zero
                    return 0;
                }
                return prev - 1; // Decrement timer
            });
        }, 1000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [userId]);

    const reset_timer = () => {
        setLogoutTimer(logoutTimerDefault); // Reset to 10 minutes
    };

    const login = (email, user_id, firstname) => {
        setEmail(email);
        setUserId(user_id);
        setFirstName(firstname);

        localStorage.setItem("user_id", user_id); // Persist token
        reset_timer();
    };

    const check_login_status = () => {
        talk(
            "ali_api/ALISecurity_Handler.php",
            "CHECK_LOGIN_STATUS",
            null,
            check_login_status_handler
        );
    }
    const check_login_status_handler = (operation, sent_data, return_data) => {
        if(return_data['status'] !== 'error') {
            setEmail(return_data['email'])
            setUserId(return_data['user_id']);
            setFirstName(return_data['firstname']);
            localStorage.setItem("user_id", return_data['user_id']);

            setFinishedLoggingIn(true);
        } else {
            logout_client_side();
        }
    }

    const logout = () => {
        //Handle Logout on the Server First
        talk(
            "ali_api/ALISecurity_Handler.php",
            "LOGOUT",
            null,
            logout_handler
        );
    };
    const logout_handler = (operation, sent_data, return_data) => {
        if(return_data['status'] === 'success') {
            //Then Handle the Local Clearing of Data Here
            logout_client_side();

            //Redirect to Login Page
            const urlPath = window.location.pathname;
            const lastPart = urlPath.substring(urlPath.lastIndexOf('/') + 1);
            if(lastPart !== 'logout') {
                document.location.href = 'logout';
            }
        } else {
            console.log("FAILED TO LOG USER OUT.");
        }
    }
    const logout_client_side = () => {
        setEmail(null);
        setUserId(null);
        setFirstName(null);
        localStorage.removeItem("user_id");

        setFinishedLoggingIn(true);
    }


    return (
        <UserContext.Provider
            value={{userId, email, firstName, login, logout, check_login_status, logoutTimer, reset_timer, finishedLoggingIn}}>
            {children}
            <div className={logoutTimer < 180 ? GenStyles.logout_modal_overlay : GenStyles.hidden}>
                <div className={GenStyles.logout_modal_modal}>
                    <h2>Session Expiration</h2>
                    <p>Your session will expire in {logoutTimer} seconds. Would you like to stay logged in?</p>
                    <div className={GenStyles.logout_modal_buttons}>
                        <button onClick={logout} className={GenStyles.logout_modal_logout_button}>
                            Logout
                        </button>
                        <button onClick={reset_timer} className={GenStyles.logout_modal_stay_button}>
                            Stay Logged In
                        </button>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
