export function talk(url, operation, post_data, handler) {
    // let postData = {
    //     operation: operation,
    //     ...post_data
    // }
    load_fetch(url, post_data instanceof FormData ? post_data : {
        operation:operation,
        ...post_data
    }, (data) => {
        //console.log(postData);
        try {
            let json_data = JSON.parse(data);
            handler(operation, post_data, json_data);
        } catch (e) {
            console.log(operation);
            console.log(e);
            console.log(data);
        }
    },'text');
}
export function load_fetch(full_url, postData, callback, textOrJson='json') {
    const options = {
        method: 'POST',
        body: postData instanceof FormData ? postData : JSON.stringify(postData),
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            headers: postData instanceof FormData ? {'Accept': 'application/json'} : {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    };

    if (textOrJson === 'json') {
        fetch(full_url, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed with HTTP code " + response.status);
                }
                return response;
            })
            .then(result => result.json())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    } else {
        fetch(full_url, options)
            .then(result => result.text())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    }
}
































// export function load_fetch(full_url, postData, callback, textOrJson='json') {
//     const options = {
//         method: 'POST',
//         body: JSON.stringify(postData),
//         cache: 'no-cache',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     };
//
//     if(textOrJson === 'json') {
//         fetch(full_url, options)
//             .then(response => {
//                 if(!response.ok) {
//                     throw new Error("Failed with HTTP code " + response.status);
//                 }
//                 return response;
//             })
//             .then(result => result.json())
//             .then(function (data) {
//                 callback(data);
//             })
//             .catch(error => {
//                 callback({error: error});
//             });
//     } else {
//         fetch(full_url, options)
//             .then(result => result.text())
//             .then(function (data) {
//                 callback(data);
//             })
//             .catch(error => {
//                 callback({error: error});
//             });
//     }
// }
