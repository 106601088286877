import React, {useContext, useEffect, useState} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import LoginStyles from "./styles/LoginStyles.module.css";
import {talk} from "./ALI_Ajax";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";

const LogoutPage = (props) => {
    const {firstName, logout, check_login_status} = useUser();

    useEffect(() => {
        logout();
    }, []);

    return <>
        {/* Header */}
        <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
            <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
            <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Applied Language Institute</b></h1>
            <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
        </div>
        <div className={LoginStyles.login_error_message}>You have been successfully logged out.</div>
        <LoginModule />
    </>
}

export default LogoutPage;