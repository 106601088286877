import React, { useState } from "react";
import EMStyles from "./styles/ExamineeManagerStyles.module.css";
import GenStyles from "./styles/GenStyles.module.css";
import { talk } from "./ALI_Ajax";

const RegisterExamineeForm = () => {
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState(null);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(null);
    const [emailErrorMessage, setEmailErrorMessage] = useState(null);
    const [studentIdErrorMessage, setStudentIdErrorMessage] = useState(null);

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        student_id: "",
    });


    //AJAX FUNCTIONS
    const submit_form = (e) => {
        e.preventDefault();

        //Clear error messages
        setFirstNameErrorMessage(null);
        setLastNameErrorMessage(null);
        setEmailErrorMessage(null);
        setStudentIdErrorMessage(null);
        setPasswordErrorMessage(null);

        //Validate examinee name
        if(!formData['firstname'].trim()) {
            setFirstNameErrorMessage("Must enter a first name.");
            return;
        }
        if(!formData['lastname'].trim()) {
            setLastNameErrorMessage("Must enter a last name.");
            return;
        }

        //Validate the Email
        if(!is_email_valid(formData['email'].trim())) {
            setEmailErrorMessage("Must enter a valid email.");
            return;
        }

        //Validate the password
        let password_validation = validate_password(formData['password']);
        if(!password_validation['valid']) {
            return;
        }

        //Validate the Student ID
        if(formData['student_id'].trim().length !== 4) {
            setStudentIdErrorMessage("Must enter the last 4 digits of the student ID or 0000 as a placeholder.");
            return;
        }

        talk(
            "ali_api/ALIExaminee_Manager_Handler.php",
            "ADD_NEW_EXAMINEE",
            formData,
            submit_form_handler
        );
    };
    const submit_form_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }


    //OTHER FUNCTIONS
    const handle_change = (e) => {
        const { name, value } = e.target;
        if(name === 'password') {
            validate_password(value);
        }
        setFormData({ ...formData, [name]: value });
    };
    const validate_password = (password) => {
        const errors = [];
        if (password.length < 8) errors.push("Password must be at least 8 characters long.");
        if (!/[A-Z]/.test(password)) errors.push("Password must contain at least one uppercase letter.");
        if (!/[a-z]/.test(password)) errors.push("Password must contain at least one lowercase letter.");
        if (!/\d/.test(password)) errors.push("Password must contain at least one digit.");
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push("Password must contain at least one special character.");

        if (errors.length > 0) {
            setPasswordErrorMessage(errors.map((item, index) => (
                <div key={index}>{item}</div>
            )))

            return { valid: false, errors };
        }
        setPasswordErrorMessage(null);
        return { valid: true, errors: [] };
    }
    const is_email_valid = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the email against the regex
        return emailRegex.test(email);
    }


    return (
        <div className={EMStyles.container}>
            <h1 className={EMStyles.title}>Register New Examinee</h1>
            <form onSubmit={submit_form} className={EMStyles.form}>
                <div className={EMStyles.form_group}>
                    <label htmlFor="firstname" className={EMStyles.label}>First Name</label>
                    <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handle_change}
                        className={EMStyles.input}
                    />
                </div>
                <div className={firstNameErrorMessage ? EMStyles.form_group : GenStyles.hidden}>
                    <span className={EMStyles.error_message}>{firstNameErrorMessage}</span>
                </div>
                <div className={EMStyles.form_group}>
                    <label htmlFor="lastname" className={EMStyles.label}>Last Name</label>
                    <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handle_change}
                        className={EMStyles.input}
                    />
                </div>
                <div className={lastNameErrorMessage ? EMStyles.form_group : GenStyles.hidden}>
                    <span className={EMStyles.error_message}>{lastNameErrorMessage}</span>
                </div>
                <div className={EMStyles.form_group}>
                    <label htmlFor="email" className={EMStyles.label}>Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handle_change}
                        className={EMStyles.input}
                    />
                </div>
                <div className={emailErrorMessage ? EMStyles.form_group : GenStyles.hidden}>
                    <span className={EMStyles.error_message}>{emailErrorMessage}</span>
                </div>
                <div className={EMStyles.form_group}>
                    <label htmlFor="password" className={EMStyles.label}>Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handle_change}
                        className={EMStyles.input}
                    />
                </div>
                <div className={passwordErrorMessage ? EMStyles.form_group : GenStyles.hidden}>
                    <span className={EMStyles.error_message}>{passwordErrorMessage}</span>
                </div>
                <div className={EMStyles.form_group}>
                    <label htmlFor="student_id" className={EMStyles.label}>Student ID (last 4 digits or '0000')</label>
                    <input
                        type="text"
                        id="student_id"
                        name="student_id"
                        value={formData.student_id}
                        onChange={handle_change}
                        className={EMStyles.input}
                    />
                </div>
                <div className={studentIdErrorMessage ? EMStyles.form_group : GenStyles.hidden}>
                    <span className={EMStyles.error_message}>{studentIdErrorMessage}</span>
                </div>
                <button type="submit" className={EMStyles.submit_button}>Register</button>
            </form>
        </div>
    );
};

export default RegisterExamineeForm;
