import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import ChatStyles from "./styles/ChatStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";

const BasicChat = (props) => {

    const [chatOutput, setChatOutput] = useState([]);
    const [prompt, setPrompt] = useState ('');
    const [pin, setPin] = useState('');
    const chatInputRef = useRef(null);
    const {userId, finishedLoggingIn} = useUser();

    //USE EFFECTS
    useEffect(() => {
        check_for_existing_session();
    }, []);
    useEffect(() => {
        console.log(chatOutput);
        let output_div = document.getElementById('output_div');

        if(!output_div) {
            return;
        }
        output_div.scrollTop = output_div.scrollHeight;

        console.log("TOKEN COST: " + calculate_token_cost());
    }, [chatOutput]);

    //AJAX FUNCTIONS
    const call_chatgpt = () => {
        console.log("Sending prompt to server:", prompt);

        let postData = {
            operation: "CHAT",
            prompt: prompt,
            pin: pin
        }

        talk("./ali_api/OpenAI_Handler.php", "CHAT", postData, call_chatgpt_handler);
    }
    const call_chatgpt_handler = (operation, sent_data, return_data) => {
        setChatOutput(return_data['response']);
        setPrompt('');
        chatInputRef.current.focus();
    }
    const clear_session = () => {
        console.log("Sending request to clear session on the server...");
        talk("./ali_api/OpenAI_Handler.php", "CLEAR_SESSION", {pin:pin}, clear_session_handler);
    }
    const clear_session_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        display_chat(return_data);
    }
    const check_for_existing_session = () => {
        console.log("Checking for existing session.");
        talk(
            "./ali_api/OpenAI_Handler.php",
            "LOAD_SESSION",
            null,
            check_for_existing_session_handler
        );
    }
    const check_for_existing_session_handler = (operation, sent_data, return_data) => {
        if(!return_data['status']) {
            setChatOutput(return_data);
        }
    }
    const text_to_speech = () => {
        talk("./ali_api/OpenAI_Handler.php", "GENERATE_SPEECH", {pin:pin}, text_to_speech_handler);
    }
    const text_to_speech_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
    }


    //BASIC FUNCTIONS
    const handle_keydown_in_textarea = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            call_chatgpt();
        }
    }
    const calculate_token_cost = () => {
        let totalTokens = 0;

        chatOutput.forEach((message) => {

            // Count words in the message content
            const wordTokens = message.content.trim().split(/\s+/).length;

            // Count punctuation (e.g., commas, periods, etc.)
            const punctuationTokens = (message.content.match(/[^\w\s]/g) || []).length;

            // Add tokens for the content and metadata overhead (role + content keys)
            const messageTokens = wordTokens + punctuationTokens + 3; // 3 for role and content keys
            console.log(messageTokens);
            totalTokens += messageTokens;
        });

        // Add 3-4 tokens for special start/end tokens
        totalTokens += 4;

        return totalTokens;
    }

    // Modal Image Gallery
    const display_chat = (content) => {
        setChatOutput(content);
    }




    return (
        <>
            {
                finishedLoggingIn ?
                <>
                    {/* Header */}
                    <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                        <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                        <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Chatbot</b></h1>
                        <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                    </div>

                {userId ?
                    <div className={ChatStyles.chat_main}>
                        <div
                            className={ChatStyles.chat_output_box}
                            id="output_div"
                        >{chatOutput && chatOutput.map((item, index) => (
                            <div key={index} className={ChatStyles.chat_line}><b>{item['role']}</b> {item['content']}</div>
                        ))}</div>
                        <div className={ChatStyles.chat_input_form}>
                            <textarea
                                id="userInput"
                                placeholder="Type your message here..."
                                className={ChatStyles.chat_input_text}
                                value={prompt}
                                onChange={event => setPrompt(event.target.value)}
                                ref={chatInputRef}
                                onKeyDown={handle_keydown_in_textarea}
                            ></textarea>
                            <input
                                type="text"
                                id="userPin"
                                placeholder="Enter your PIN"
                                className={ChatStyles.chat_input_text}
                                value={pin}
                                onChange={event => setPin(event.target.value)}
                            />
                            <button
                                id="submitButton"
                                className={ChatStyles.chat_button}
                                onClick={call_chatgpt}
                            >
                                Submit
                            </button>
                            <button
                                id="clearSessionButton"
                                className={ChatStyles.chat_button}
                                onClick={clear_session}
                            >
                                Clear Session
                            </button>
                            <button
                                className={ChatStyles.chat_button}
                                onClick={text_to_speech}
                            >
                                Test Speech
                            </button>
                        </div>
                    </div>
                    : <LoginModule />}
                </> :
                <div>loading</div>
            }
        </>
    );
}

export default BasicChat;
