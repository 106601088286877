import React from "react";
import Styles from "./textbook_styles.module.css";
import HeaderImage from "./images/banner.jpg";

const WritingTextbook = () => {
    return (
        <div className={Styles.container}>
            <h1 className={Styles.mainHeading}>How to Write a Paragraph</h1>
            <div>
                <img className={Styles.header_image} src={HeaderImage} />
            </div>
            <p className={Styles.intro}>
                Writing a paragraph is an important skill for sharing your ideas
                clearly. Let’s learn the structure of a good paragraph step by step!
            </p>

            <h2 className={Styles.subHeading}>1. What is a Paragraph?</h2>
            <p className={Styles.paragraph}>
                A paragraph is a group of sentences that talk about{" "}
                <strong>one main idea</strong>. It is like a small piece of a story or
                essay. All the sentences in a paragraph should be{" "}
                <strong>connected</strong> and support the main idea.
            </p>

            <h2 className={Styles.subHeading}>2. The Three Parts of a Paragraph</h2>

            <h3 className={Styles.sectionHeading}>a) The Topic Sentence</h3>
            <p className={Styles.paragraph}>
                The topic sentence is the first sentence of your paragraph. It tells
                the reader what your paragraph is about. For example:
            </p>
            <p className={Styles.example}>
                "My favorite season is summer because I enjoy the warm weather."
            </p>

            <h3 className={Styles.sectionHeading}>b) Supporting Sentences</h3>
            <p className={Styles.paragraph}>
                These sentences give more details or examples about the topic. They
                explain <strong>why</strong> or <strong>how</strong> the main idea is
                true. For example:
            </p>
            <p className={Styles.example}>
                "In summer, I can go swimming in the lake. I also enjoy eating ice cream
                to stay cool."
            </p>

            <h3 className={Styles.sectionHeading}>c) The Closing Sentence</h3>
            <p className={Styles.paragraph}>
                The closing sentence is the last sentence in your paragraph. It{" "}
                <strong>restates</strong> the main idea or gives a final thought. For
                example:
            </p>
            <p className={Styles.example}>
                "Summer is the best season for relaxing and having fun outside."
            </p>

            <h2 className={Styles.subHeading}>3. Tips for a Great Paragraph</h2>
            <ul className={Styles.list}>
                <li>
                    <strong>Stick to one idea:</strong> Don’t mix too many ideas in one
                    paragraph.
                </li>
                <li>
                    <strong>Use connectors:</strong> Words like <em>and, but, because,
                    so</em> help your sentences flow.
                </li>
                <li>
                    <strong>Check your sentences:</strong> Make sure every sentence
                    relates to the main idea.
                </li>
            </ul>

            <p className={Styles.closing}>
                Now you are ready to write your own paragraph! Start with a strong topic
                sentence, add some supporting sentences, and finish with a good closing
                sentence. Happy writing! 😊
            </p>
        </div>
    );
};

export default WritingTextbook;
