export const ALI_Recorder = {
    mediaRecorder: null,
    audioChunks: [],
    // audioPlayback: document.getElementById("audioPlayback"),
    // downloadLink: document.getElementById("downloadLink"),
    // startButton: document.getElementById("startRecord"),
    // stopButton: document.getElementById("stopRecord"),

    supportedTypes: [
        "audio/webm;codecs=opus",
        "audio/ogg;codecs=opus",
        "audio/wav",
        "audio/mpeg"
    ],




    // Initialize the Recorder
    init: function () {
        this.supportedTypes.forEach((type) => {
            console.log(type, MediaRecorder.isTypeSupported(type) ? "Supported" : "Not Supported");
        });
        //this.startButton.addEventListener("click", this.startRecording.bind(this));
        //this.stopButton.addEventListener("click", this.stopRecording.bind(this));
    },

    // Start recording audio
    startRecording: async function () {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(stream);

            console.log("Selected MIME type:", this.mediaRecorder.mimeType);

            // Reset audio chunks
            this.audioChunks = [];
            this.mediaRecorder.addEventListener("dataavailable", (event) => {
                this.audioChunks.push(event.data);
            });

            this.mediaRecorder.start();
            console.log("Recording started...");

            // Toggle button states
            //this.startButton.disabled = true;
            //this.stopButton.disabled = false;
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    },

    // Stop recording audio
    stopRecording: function () {
        if (this.mediaRecorder) {
            this.mediaRecorder.stop();
            console.log("Recording stopped...");

            // Toggle button states
            //this.startButton.disabled = false;
            //this.stopButton.disabled = true;

            // Handle when recording stops
            this.mediaRecorder.addEventListener("stop", this.processRecording.bind(this));
        }
    },

    get_blob: function () {
        return new Blob(this.audioChunks, { type: "audio/webm" });
    },

    // Process the recorded audio
    processRecording: function () {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
        const audioURL = URL.createObjectURL(audioBlob);



        // Set audio playback source
        //this.audioPlayback.src = audioURL;

        // Set download link
        //this.downloadLink.href = audioURL;
        //this.downloadLink.style.display = "inline";
        console.log("Audio processing complete.");
        return audioURL;
    }
};

// Initialize the Recorder object
//ALI_Recorder.init();
