import React, {useEffect, useState, useRef, useContext} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import STTStyles from "./styles/STTStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import {ALI_Recorder} from "./ALI_Recorder.js";
import text_to_speech from "./text_to_speech";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";

const SpeechToText = (props) => {
    const [prompt, setPrompt] = useState ('');
    const [pin, setPin] = useState('');
    const chatInputRef = useRef(null);
    const [filename, setFilename] = useState('');
    const [audioTag, setAudioTag] = useState(<></>);
    const [textOutput, setTextOutput] = useState('...text will appear here...');
    const {userId, logout, finishedLoggingIn} = useUser();


    //USE EFFECTS
    useEffect(() => {
        generate_audio_tag();
    }, [filename]);
    useEffect(() => {
        console.log("AUDIO TAG: " + audioTag);
    }, [audioTag])
    useEffect (() => {
        console.log(userId);
    }, [userId]);

    //AJAX FUNCTIONS
    const speech_to_text = () => {
        console.log("Sending prompt to server:", prompt);


        // let postData = {
        //     operation: "EXTRACT_TEXT_FROM_SPEECH",
        //     pin: pin,
        //     file: ALI_Recorder.get_blob()
        // }
        let postData = new FormData();
        postData.append("operation", "EXTRACT_TEXT_FROM_SPEECH");
        postData.append("pin", pin);
        postData.append("file", ALI_Recorder.get_blob(), "recording.webm");
        talk("./ali_api/OpenAI_Handler.php", "EXTRACT_TEXT_FROM_SPEECH", postData, speech_to_text_handler);
    }
    const speech_to_text_handler = (operation, sent_data, return_data) => {
        if(return_data['error']) {
            setTextOutput("ERROR: " + return_data['error']);
        } else {
            setTextOutput(return_data['response']);
        }

        console.log(return_data);
    }


    //BASIC FUNCTIONS
    const handle_keydown_in_textarea = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            text_to_speech();
        }
    }
    const generate_audio_tag = () => {
        console.log("Generating new audio tag for file: " + filename);

        if(!filename) {
            return <></>
        }

        setAudioTag(
        <audio key={filename} controls autoPlay={true}>
            <source src={`../ali_api/${filename}`} type="audio/mpeg"/>
            Your browser does not support the audio tag.
        </audio>
        )
    }
    const initialize_recorder = () => {
        ALI_Recorder.init();
        ALI_Recorder.startRecording();
    }
    const stop_recording = () => {
        ALI_Recorder.stopRecording();
    }
    const process_audio = () => {
        document.getElementById('audioPlayback').src = ALI_Recorder.processRecording();
    }


    return (
        <>
            {
                finishedLoggingIn ?
                <>
                    {/* Header */}
                    <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                        <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                        <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Speech to Text</b></h1>
                        <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                    </div>

                    {userId ?
                        <div className={STTStyles.main}>
                            <div
                                className={STTStyles.stt_output_box}
                                id="output_div"
                            >{textOutput}</div>
                            <audio id="audioPlayback" controls></audio>
                            <div className={STTStyles.stt_input_form}>
                                {/*<textarea*/}
                                {/*    id="userInput"*/}
                                {/*    placeholder="Type your message here..."*/}
                                {/*    className={STTStyles.stt_input_text}*/}
                                {/*    value={prompt}*/}
                                {/*    onChange={event => setPrompt(event.target.value)}*/}
                                {/*    ref={chatInputRef}*/}
                                {/*    onKeyDown={handle_keydown_in_textarea}*/}
                                {/*></textarea>*/}
                                <input
                                    type="text"
                                    id="userPin"
                                    placeholder="Enter your PIN"
                                    className={STTStyles.stt_input_text}
                                    value={pin}
                                    onChange={event => setPin(event.target.value)}
                                />
                                <button
                                    className={STTStyles.stt_button}
                                    onClick={speech_to_text}
                                >
                                    Extract Text
                                </button>
                                <button
                                    className={STTStyles.stt_button}
                                    onClick={initialize_recorder}
                                >
                                    Start Recorder
                                </button>
                                <button
                                    className={STTStyles.stt_button}
                                    onClick={stop_recording}
                                >
                                    Stop Recorder
                                </button>
                                <button
                                    className={STTStyles.stt_button}
                                    onClick={process_audio}
                                >
                                    Process Audio
                                </button>
                            </div>
                        </div>
                     : <LoginModule />}
                </> :
                <div>loading</div>
            }
        </>
    )
}

export default SpeechToText;
